<template>
	<v-sheet class="contact-main-listing" style="height: calc(100vh - 88px)">
		<v-layout>
			<v-flex class="py-0">
				<v-select
					style="width: 220px"
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="listingStatus"
					solo
					v-on:change="statusFilterRows($event)"
					:menu-props="{ offsetY: true, contentClass: 'rounded-lg' }"
				>
					<template v-slot:item="{ item }">
						<v-list-item-action>
							<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
								<span class="white--text">{{ item.status_count }}</span>
							</v-avatar>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-select>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<template>
					<v-flex
						v-if="
							(Login_user && Login_user.users_type == 'accounts') ||
							(AllowAnyOforGetRoleNType(['admin']) && Login_user && Login_user.users_type == 'sales')
						"
						class="flex d-flex align-center justify-content-end pr-1"
					>
						<div class="mr-2 fw-600">Filter By</div>
						<div class="ml-3 filterTagSelect">
							<v-autocomplete
								:items="users"
								:menu-props="{ bottom: true, offsetY: true }"
								outlined
								hide-details
								hide-top-margin
								clearable
								class="filterSelect"
								@click:clear="filter = {}"
								item-text="display_name"
								:disabled="pageLoading"
								style="min-width: 270px !important; width: 270px !important"
								v-model="filter.user_filter"
								item-value="id"
								placeholder="Sales Person"
								@change="applyFilter"
							>
								<template v-slot:selection="data">
									<v-chip x-small v-bind="data.attrs" :input-value="data.selected">
										<v-avatar left v-if="data.item.profile_img">
											<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>

										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-list-item-avatar>
										<div class="fw-500 text-uppercase">
											<p>{{ data.item.display_name }}</p>
											<span class="text-muted text-lowercase">{{ data.item.email }}</span>
										</div>
									</template>
								</template>
							</v-autocomplete>
						</div>
					</v-flex>
					<v-btn
						v-if="getPermission('company:create')"
						depressed
						tile
						color="red darken-4"
						class="text-white"
						@click="CreateCompanyDrawer()"
						:disabled="pageLoading"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<!-- <v-btn depressed :disabled="pageLoading" color="blue darken-4" class="text-white" tile>
						<v-icon>mdi-cog</v-icon>
					</v-btn> -->

					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="referess">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('member')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('company', cols.key)"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>

		<CompanyListing v-on:createSuccess="getListing()"></CompanyListing>
		<template v-if="false">
			<TaskKanban></TaskKanban>
		</template>

		<CreateCompanyDrawer
			v-if="create_comapny"
			:drawer="create_comapny"
			title-status="Create"
			v-on:createSuccess="getListing()"
			v-on:close="create_comapny = false"
		></CreateCompanyDrawer>
		<div style="position: fixed; bottom: 100px; right: 20px">
			<div class="position-relative">
				<div class="call-btn-underlay" style="background-color: rgb(243, 165, 165)"></div>
				<v-btn
					fab
					dark
					large
					v-if="getPermission('company:create')"
					color="red darken-4"
					@click="CreateCompanyDrawer()"
					:disabled="pageLoading"
				>
					<v-icon dark>mdi-plus </v-icon>
				</v-btn>
			</div>
		</div>
	</v-sheet>
</template>
<script>
import CompanyListing from "@/view/module/company/CompanyListing";
import { toSafeInteger } from "lodash";
import ListingMixin from "@/core/mixins/listing.mixin";
import TaskKanban from "@/view/module/task/TaskKanban";
import CreateCompanyDrawer from "@/view/pages/leads/create/CreateCompanyDrawer";
import { GET, QUERY } from "@/core/services/store/request.module";
import { TaskEventBus } from "@/core/lib/task.form.lib";
import { mapGetters } from "vuex";
import { getCurrentUser } from "@/core/services/jwt.service";
import objectPath from "object-path";
import SettingsMixin from "@/core/mixins/settings.mixin";

import {
	SET_TBODY,
	SET_ACTION,
	SET_BULK_ACTION,
	SET_STATUS,
	SET_FILTER,
	SET_THEAD,
	SET_CURRENT_PAGE,
	SET_SHOWING_STRING,
	SET_TOTAL_PAGE,
	BEFORE_DESTROY,
	SET_SEARCH_MODEL,
} from "@/core/services/store/listing.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
	name: "company-listing",
	mixins: [ListingMixin, SettingsMixin],
	data() {
		return {
			viewType: true,
			labelItems: ["High", "Medium", "Low"],
			filter_label: "High",
			create_comapny: false,
			pageTitle: "Company",
			pageBreadcrumbs: [{ text: "company", disabled: true }],
			endpoint: "company",
			defaultFilter: {},
			settingsEndpoint: "customer-setting",
			addvanceFilterAarry: [],
			statusFilterData: [],
			editTask: {},
			attendieList: {},
			drawerFilter: false,
			priorityList: [],
			status: "all",
			users: [],
			// listingStatus: [
			// 	{
			// 		id: 1,
			// 		text: "All Company",
			// 		value: "all",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "blue",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 4,
			// 	},
			// 	{
			// 		id: 2,
			// 		text: "Not Contacted",
			// 		value: "not-contacted",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "purple",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 3,
			// 	},
			// 	{
			// 		id: 3,
			// 		text: "Follow UP",
			// 		value: "follow-up",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "orange",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 0,
			// 	},
			// 	{
			// 		id: 4,
			// 		text: "Meeting",
			// 		value: "meeting",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "pink",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 0,
			// 	},
			// 	{
			// 		id: 5,
			// 		text: "Quotation",
			// 		value: "quotation",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "cyan",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 1,
			// 	},
			// 	{
			// 		id: 6,
			// 		text: "Rejected",
			// 		value: "reject",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "red",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 2,
			// 	},
			// 	{
			// 		id: 7,
			// 		text: "Accepted",
			// 		value: "accept",
			// 		type: 1,
			// 		provider_type: null,
			// 		seo: 0,
			// 		hosting: 0,
			// 		domain: 0,
			// 		status_color: "green",
			// 		count: 0,
			// 		child_name: null,
			// 		cost: null,
			// 		activation_date: null,
			// 		renewal_basis: null,
			// 		renewal_date: null,
			// 		status_count: 4,
			// 	},
			// ],
		};
	},
	components: {
		TaskKanban,
		CreateCompanyDrawer,
		CompanyListing,
	},
	methods: {
		applyFilter() {
			const { user_filter } = this.filter;
			const { name, params, query } = this.$route;
			this.$router.push({ name, params, query: { ...query, user_filter } });
			this.getListing();
		},
		statusFilterRows(value) {
			//this.$route.query.page ? this.$route.query.page : null,
			this.status = value;
			this.pageLoading = true;
			this.allFiltersStatus(value);
			this.$store
				.dispatch(QUERY, {
					url: `company`,
					data: {
						status: value,
						date:
							this.filter_reminder_date && this.filter_reminder_date.length > 0
								? this.filter_reminder_date
								: this.$route.query.date,
						product_type: this.productType ? this.productType : null,
						opportunity: this.opportunity || null,
						user_filter: this.user_filter || null,
						source: this.filter_source || null,
						page: 1,
						search: this.listingSearch || undefined,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, 1);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					/* this.addvanceFilterAarry = []; */
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		referess() {
			window.location.reload();
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		open_drawer(id) {
			this.create_comapny = true;
			this.$store
				.dispatch(GET, { url: `single-task/${id}` })
				.then((data) => {
					this.editTask = data.tasks;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		viewTypedata() {
			this.viewType = !this.viewType;
			this.$router.push({
				name: "company",
				query: {
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},

		allFiltersStatus(value) {
			this.$router.push({
				name: "company",
				query: {
					status: value,
					t: new Date().getTime(),
				},
			});
		},
		CreateCompanyDrawer() {
			this.editTask = {};
			this.attendieList = {};
			this.create_comapny = true;
		},
		topBarFilter() {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `task`,
					data: {
						status: "all",
						user_filter: this.user_filter,
					},
				})
				.then((data) => {
					this.$router.push({
						name: "company",
						query: {
							user_filter: this.user_filter,
							t: new Date().getTime(),
							status: this.status,
							search: this.listingSearch || undefined,
						},
					});
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "company",
				},
			});
		},
	},
	computed: {
		...mapGetters(["currentUser", "moduleSettings", "tbody", "thead", "sortedThead"]),
	},
	watch: {
		moduleSettings: {
			immediate: true,
			handler(settings) {
				if (Object.keys(settings).length) {
					this.users = objectPath.get(settings, "sales_users") || [];
					this.customerStatus = objectPath.get(settings, "status") || [];
				}
			},
		},
	},
	beforeMount() {
		const { query } = this.$route;
		if (Object.keys(query).length) {
			this.filter.user_filter = +query.user_filter || null;
		}
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = toSafeInteger(this.$route.query.user_filter);
		}
		this.Login_user = getCurrentUser() || null;

		/* 	this.defaultFilter = {
			user_filter: this.user_filter ? this.user_filter : null,
		}; */
	},
	beforeDestroy() {
		this.$store.dispatch(BEFORE_DESTROY);
		if (!this.internal) {
			this.$store.dispatch(SET_BREADCRUMB, []);
		}
		this.$store.dispatch(SET_SEARCH_MODEL, null);
	},
	mounted() {
		// this.getTaskStatus();
		this.viewType = this.$route.query.viewType;
		let filterQuery = this.$route.query;

		this.$router.push({
			name: "company",
			query: {
				...filterQuery,
				viewType: this.viewType,
				t: new Date().getTime(),
			},
		});
		TaskEventBus.$on("refresh", () => {
			this.getListing();
		});
		/* this.topBarFilter(); */
	},
};
</script>
<style scoped>
.bt-table tbody tr:nth-child(even) {
	background-color: rgb(237, 242, 251);
}
</style>
